<template>
  <ValidationObserver v-slot="{ invalid }">
    <form id="form-login" class="form-horizontal form-control-borderless">
      <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-envelope"></i></span>
              <input
                v-model="email"
                autocomplete="off"
                type="text"
                id="login-username"
                name="login-username"
                class="form-control input-lg"
                placeholder="Email"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|min:6" v-slot="{ errors }" tag="div">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
              <input
                v-model="password"
                type="password"
                id="login-password"
                name="login-password"
                class="form-control input-lg"
                placeholder="Password"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="min:6|max:6" v-slot="{ errors }" tag="div" v-if="show">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="col-xs-12">
            <div class="input-group">
              <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
              <input
                v-model="auth"
                type="text"
                class="form-control input-lg"
                placeholder="Your Authentication Code If exists"
              />
            </div>
            <div v-show="errors[0]" class="animation-slideUp help-block">
              {{ errors[0] }}
            </div>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group">
        <vue-recaptcha
          class="form-login__recaptcha"
          ref="recaptcha"
          @verify="onVerify"
          @expired="onExpired"
          :sitekey="siteKey"
        >
        </vue-recaptcha>
      </div>
      <div class="form-group">
        <div class="col-xs-12 text-right text-waring">
          <router-link to="/forget-password">
            <p class="text-forgot" style="margin:0;line-height:1.3;font-weight:400;font-size:14px">
              Forgot your password?
            </p>
          </router-link>
        </div>
      </div>
      <div class="form-group">
        <div class="col-xs-12 text-center">
          <button
            type="button"
            class="btn btn-sm btn-login"
            :disabled="invalid || !isRecaptcha"
            @click="handleSignIn"
          >
            Login
          </button>
        </div>
      </div>

      <div class="form-group">
        <div class="col-xs-12 text-center">
          <router-link to="/signup">
            <button type="button" class="btn btn-sm btn-sub">
              Create a new account
            </button></router-link
          >
        </div>
      </div>
      <div class="form-group">
        <div class="col-xs-12 text-center">
            <button @click="statusLoginMeta = true " type="button" class="btn btn-sm btn-sub">
              Login with connect wallet
            </button>
        </div>
        <vue-metamask
            v-if="statusLoginMeta == true"
            @onComplete="onComplete"
          />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { signIn, signInWithMeta, setAuthToken } from "@/services/auth";
import recaptcha from "@/mixins/recaptcha";
import VueMetamask from 'vue-metamask';
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "SignInForm",
  components: {
    VueRecaptcha,
    VueMetamask
  },
  mixins: [recaptcha],
  data: () => ({
    show: false,
    email: "",
    password: "",
    auth: "",
    statusLoginMeta: false,
  }),
  methods: {
    onComplete(data){
      if(data.metaMaskAddress){
        this.handleSignInWithMeta(data.metaMaskAddress);
      }
      //console.log(data);
    },
    async handleSignInWithMeta(metaMaskAddress) {
      this.$startLoading();
      const { data, status, message, errors } = await signInWithMeta(metaMaskAddress);
      this.$finishLoading();
      if (errors && errors.auth == false) {
        this.$toast.success("Please Enter Your Authentication Code In Form", {});
        this.show = true;
      } else if (status) {
        setAuthToken(data.token);
        this.$router.push({
          name: "dashboard"
        });
      } else {
        this.$toast.error(message, {});
      }
    },
    async handleSignIn() {
      this.$startLoading();
      const { data, status, message, errors } = await signIn(this.email, this.password, this.auth);
      this.$finishLoading();
      if (errors && errors.auth == false) {
        this.$toast.success("Please Enter Your Authentication Code In Form", {});
        this.show = true;
      } else if (status) {
        setAuthToken(data.token);
        this.$router.push({
          name: "dashboard"
        });
      } else {
        this.$toast.error(message, {});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-login {
  &__recaptcha {
    display: flex;
    justify-content: center;
  }
}
</style>
