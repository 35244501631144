<template>
  <div>
    <auth-layout>
      <sign-in-form></sign-in-form>
    </auth-layout>
    <v-modal
      name="notification"
      :width="'100%'"
      :height="'auto'"
      :adaptive="true"
      :styles="'overflow-y: auto;max-width:500px;max-height:85vh;right:0;margin:auto'"
    >
      <div class="title">
        <button @click="HideModalNoti()" class="btn-close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <carousel :per-page="1" :mouse-drag="false" :autoplay="true">
        <slide v-for="NotiImage in NotiImages" :key="NotiImage.id">
          <img :src="NotiImage.image" width="100%" />
        </slide>
      </carousel>
    </v-modal>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import SignInForm from "@/components/auth/SignInForm";
import { isLoggedIn } from "@/services/auth";
import AuthLayout from "./layouts/Auth";
import { mapGetters } from "vuex";

export default {
  name: "SignIn",
  computed: {
    ...mapGetters({
      NotiImages: "dashboard/NotiImage"
    })
  },
  components: {
    AuthLayout,
    SignInForm,
    Carousel,
    Slide
  },
  beforeCreate() {
    this.$store.dispatch("dashboard/req_getNotiImage");
    if (isLoggedIn()) this.$router.push({ name: "dashboard" });
  },
  watch: {
    NotiImages: {
      handler(val, oldval) {
        if (val.length > 0) {
          this.$nextTick(() => {
            this.openNotification();
          });
        }
      }
    }
  },
  methods: {
    HideModalNoti() {
      this.$modal.hide("notification");
    },
    openNotification() {
      this.$modal.show("notification");
    }
  }
};
</script>

<style>
.vm--modal {
  background: #0000 !important;
  box-shadow: none;
}
</style>
