import { RECAPTCHA_KEY } from '@/config';

export default {
  data: () => ({
    isRecaptcha: false,
    siteKey: RECAPTCHA_KEY,
  }),
  methods: {
    onVerify(response) {
      if (response) this.isRecaptcha = true;
    },
    onExpired() {
      this.isRecaptcha = false;
    },
  },
};
